import _get from 'lodash/get'
import { hoaContactsStore } from '@/services/HoaContacts/store'

export const methods = {
  determineRows: async function(list) {
    if (!Array.isArray(list)) {
      return
    }

    var rows = list.map(entry => {
      const description = _get(entry, 'description', '')
      const title = _get(entry, 'title', '')
      const email = _get(entry, 'email', '')
      const phone = _get(entry, 'phone', '')

      return {
        title,
        description,
        email,
        phone
      }
    })

    this.rows = rows
  },

  async confirmDeleteHoaContact(entry) {
    this.$buefy.dialog.confirm({
      title: 'Deleting Hoa Contact',
      message: `Are you sure you want to <b>delete</b> this Hoa Contact, "${entry.title}"?`,
      confirmText: 'Delete Hoa Contact',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: async () => await this.deleteHoaContact(entry.hoaContactID)
    })
  },

  async deleteHoaContact(hoaContactID) {
    try {
      await hoaContactsStore.dispatch('deleteHoaContacts', {
        hoaContactID,
        done: () => {
          this.loading = false
          this.reload()
        }
      })
    } catch (e) {
      console.debug('There was an issue deleting this hoa contact.' + e)
    }
  },

  editHoaContact(hoaContact) {
    if (hoaContact) {
      this.toggle = true
      this.selectedHoaContact = hoaContact
    }
  },

  createHoaContactModal() {
    this.toggle = true
    this.selectedHoaContact = null
  },

  async reload() {
    this.loading = true

    this.rows = []

    await hoaContactsStore
      .dispatch('getHoaContactsList', {
        hoaID: this.currentHoaId
      })
      .then(({ list }) => {
        this.determineRows(list)
        this.loading = false
      })

    this.loading = false
  }
}
