import moment from 'moment'

export const data = () => ({
  isDebug: true,
  loading: false,
  showFilters: false,
  toggle: false,
  formatDateFn: function(value) {
    return value != null ? moment(value, 'YYYY/MM/DD HH:mm:ss').format('MM-DD-YYYY') : ''
  },

  filters: {
    show: false
  },

  region: {},

  rows: [],

  columns: [
    {
      field: 'title',
      label: 'Title',
      aria: 'Title',
      width: '25%',
      sortable: true,
      searchable: true
    },
    {
      field: 'description',
      label: 'Description',
      aria: 'Description',
      width: '35',
      sortable: true,
      searchable: true
    },
    {
      field: 'email',
      label: 'Email',
      aria: 'Email',
      width: '20%',
      sortable: true,
      searchable: true
    },
    {
      field: 'phone',
      label: 'Phone',
      aria: 'Phone',
      date: true,
      width: '20%',
      sortable: true,
      searchable: true
    }
  ]
})
