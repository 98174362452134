<template>
  <PageContentLayoutOnly role="region">
    <div class="pb-2" v-for="item in rows" :key="item.hoaContactID">
      <div class="container notification is-white">
        <div class="columns">
          <div class="column is-8 font-bold" position="is-left">{{ item.title }}</div>
          <div class="column is-1" position="is-right">Phone:</div>
          <div class="column is-3" position="is-left">{{ item.phone }}</div>
        </div>
        <div class="columns">
          <div class="column is-8" position="is-left">{{ item.description }}</div>
          <div class="column is-1" position="is-right">Email:</div>
          <div class="column is-3" position="is-left">{{ item.email }}</div>
        </div>
      </div>
    </div>
    <div v-if="rows.length === 0">
      <div class="container notification is-white">
        There are currently no contacts assigned.
      </div>
    </div>
  </PageContentLayoutOnly>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import PageContentLayoutOnly from '@/containers/PageContentLayoutOnly'
import { methods } from './keys/methods'
import { data } from './keys/data'

export default {
  name: 'HoaContacts',
  components: {
    PageContentLayoutOnly
  },

  data,

  methods,

  computed: {
    ...mapState({
      ...mapGetters('user', ['authUser', 'retrieveUser', 'currentHoaId'])
    })
  },

  watch: {},

  mounted() {
    this.reload()
  },

  i18n: {
    messages: {
      en: { hoaContacts: { title: 'HOA Contacts' } }
    }
  }
}
</script>

<style></style>
